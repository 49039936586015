<template>
  <div class="box-item" @click="clickItem()">
    <div class="video">
      <img src="../../assets/images/doc.png" alt="" />
    </div>
    <div class="bot">
      <div class="p">
        {{ item.docTitle }}
      </div>
      <div class="ft">
        <div class="user">{{ item.userNickName }}</div>
        <div class="time">
          {{
            `${item.createTime[0]}-${item.createTime[1]}-${item.createTime[2]}`
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from '../../store';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      i: {
        img: 'https://polyjetclub-backend.polysmartchain.com/images/normal/866.png',
      },
    };
  },
  methods: {
    clickItem() {
      console.log('1');
      if (this.item.type == 1) {
        console.log('2');
        window.open(this.item.targetUrl, '_blank');
      } else {
        this.store.actDocId = this.item.id;
        window.sessionStorage.setItem('actDocId', this.item.id);
        this.$router.push('/wordDet');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-item {
  /* 矩形 6 */
  width: 100%;
  height: 395px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  margin-bottom: 33px;
  .video {
    /* 矩形 7 */
    width: 100%;
    height: 305px;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    img {
      /* O1CN01gmmBhj1Cv4l4agZFt_!!0-item_pic */

      position: absolute;
      left: 50%;
      top: 31px;
      transform: translate(-50%, 0);
      width: 169px;
      height: 232px;
    }
  }
  .bot {
    .p {
      /* 稳定币发展历程稳定币发展历程稳定币发展历程稳定币发展历程 */
      font-size: 16px;
      height: 50px;
      line-height: 20px;
      letter-spacing: 0em;
      padding: 7px 9px 0px;
      margin-bottom: 9px;
      color: #333333;
      // overflow: hidden;
      // 超出省略号
      overflow: hidden;
    }
    .ft {
      padding: 0px 9px;
      justify-content: space-between;
      display: flex;
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
