<template>
  <div class="itemBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="综合" name="first0"> </el-tab-pane>
      <el-tab-pane label="精品" name="first"> </el-tab-pane>
      <!-- <el-tab-pane label="操作教程" name="second"> </el-tab-pane>
      <el-tab-pane label="体系介绍" name="third"> </el-tab-pane>
      <el-tab-pane label="其他" name="fourth"> </el-tab-pane> -->
      <el-tab-pane
        v-for="(item, index) in store.topics"
        :key="index"
        :label="item.title"
        :name="item.id + ''"
      >
      </el-tab-pane>
    </el-tabs>
    <el-row :gutter="13">
      <el-col :span="6" v-for="(item, index) in videoList" :key="index"
        ><box-2-item :it="item"></box-2-item
      ></el-col>
    </el-row>
    <div class="imgBox"></div>
    <el-pagination
      background
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <div class="block"></div>
  </div>
</template>
<script>
import Store from '../../store';
import box2Item from './box2-item.vue';
export default {
  components: { box2Item },
  mounted() {
    // this.getVideoStateList();
    if (window.sessionStorage.getItem('act')) {
      this.act = window.sessionStorage.getItem('act');
    }
    if (window.sessionStorage.getItem('activeName')) {
      this.activeName = window.sessionStorage.getItem('activeName');
    }
    if (this.activeName == 'first0') {
      this.getZHVideoList();
    } else if (this.activeName == 'first') {
      this.getJVideoList();
    } else {
      this.getVideoList();
    }
  },
  data() {
    return {
      store: Store.state,
      activeName: 'first0',
      act: '',
      videoList: [],
      total: 0,
      pageNum: 1,
      pageSize: 100,
    };
  },
  methods: {
    // 选择某一个选项
    handleClick(tab) {
      // console.log(tab.index);
      // console.log(tab, event);
      this.pageSize = 8;
      this.pageNum = 1;
      // 如果是精品
      if (tab.index == 0) {
        this.pageSize = 100;
        this.getZHVideoList();
      } else if (tab.index == 1) {
        // this.act = this.store.topics[tab.index - 1].id;
        this.getJVideoList();
      } else {
        this.act = this.store.topics[tab.index - 2].id;
        this.getVideoList();
      }
      window.sessionStorage.setItem('act', this.act);
      window.sessionStorage.setItem('activeName', this.activeName);
    },
    // 获取视频分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${1}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.store.topics = res.data;
    },
    // 获取精品视频列表
    async getJVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/boutiqueVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.videoList = res.data.records;
      this.total = Number(res.data.total);
    },
    // 获取综合视频列表
    async getZHVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/allVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.videoList = res.data.records;
      this.total = Number(res.data.total);
    },
    // 获取视频列表
    async getVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/topicVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        topicId: this.act,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.videoList = res.data.records;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      if (this.activeName == 'first') {
        this.getJVideoList();
      } else {
        this.getVideoList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  // .el-tab-pane {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }
  .el-tabs {
    margin: 30px 0;
  }
}
</style>
