<template>
  <div class="wl-home">
    <div class="w">
      <box-1></box-1>
      <box-2></box-2>
      <box-3></box-3>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
import Box1 from './box1.vue';
import Box2 from './box2.vue';
import Box3 from './box3.vue';
export default {
  components: {
    Box1,
    Box2,
    Box3,
  },
  mounted() {},

  data() {
    return {
      uti: Store.uti,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wl-home {
  // height: 600px;
  // margin-top: 200px;
  padding-bottom: 50px;
  min-height: 600px;
  .itemBox {
    margin-bottom: 50px;
  }
}
</style>
