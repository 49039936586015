<template>
  <div class="itemBox" v-if="list.length > 0 && false">
    <div class="block">
      <el-carousel trigger="click" height="734px">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <!-- <h3 class="small">{{ item }}</h3> -->
          <img
            @click="uti.goPath(item.targetUrl)"
            :src="item.imageUrl"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  mounted() {
    this.getDate();
  },
  data() {
    return {
      uti: Store.uti,
      list: [
        {
          imageUrl:
            "https://polyjetclub-backend.polysmartchain.com/images/normal/300.png",
        },
        {
          imageUrl:
            "https://polyjetclub-backend.polysmartchain.com/images/normal/200.png",
        },
        {
          imageUrl:
            "https://polyjetclub-backend.polysmartchain.com/images/normal/100.png",
        },
      ],
    };
  },
  methods: {
    async getDate() {
      let { data: res } = await this.$http.get("/advertisement/list");
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.list = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  .el-carousel__item img {
    width: 100%;
    height: 100%;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
