<template>
  <a href="javascript:;">
    <div class="box-item" @click="goVideoDec()">
      <div class="video">
        <img
          class="videoThumbnail"
          :src="it.videoThumbnail || it.imageUrl"
          alt=""
        />
        <div class="jing" v-if="it.boutiqueStatus == 1">精品</div>
        <div class="ft">
          <div class="l">
            <div class="vant">
              <svg class="green icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-dianzan1"></use>
              </svg>
              {{ it.loveNum }}
            </div>
            <div class="watch">
              <svg class="green icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-guankan"></use>
              </svg>
              {{ it.playNum }}
            </div>
          </div>
          <!-- <div class="r">12:32</div> -->
        </div>
      </div>
      <div class="bot">
        <div class="p">
          {{ it.videoTitle || it.title }}
        </div>
        <div class="ft">
          <div class="user">{{ it.userNickName || it.author }}</div>
          <div class="time">{{ uti.changeTime(it.createTime) }}</div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import Store from "../../store";
export default {
  props: {
    it: {
      type: Object,
      default() {
        return {
          img: "https://polyjetclub-backend.polysmartchain.com/images/normal/866.png",
          boutiqueStatus: 0, //	是否精品 1.是 0.否	integer
          createTime: "", //  创建时间	string
          id: "", //	视频id	integer
          loveNum: "", //  点赞量	integer
          playNum: "", //  播放量	integer
          updateTime: "", //	更新时间	string
          userNickName: "", //  用户昵称	string
          videoThumbnail: "", //	视频封面	string
          videoTitle: "", //  视频标题	string
        };
      },
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      i: {
        img: "https://polyjetclub-backend.polysmartchain.com/images/normal/866.png",
        boutiqueStatus: 0, //	是否精品 1.是 0.否	integer
        createTime: "", //  创建时间	string
        id: "", //	视频id	integer
        loveNum: "", //  点赞量	integer
        playNum: "", //  播放量	integer
        updateTime: "", //	更新时间	string
        userNickName: "", //  用户昵称	string
        videoThumbnail: "", //	视频封面	string
        videoTitle: "", //  视频标题	string
      },
    };
  },
  methods: {
    // 前往视频详情
    goVideoDec() {
      // this.store.actVideoId = this.it.id;
      // 设置地址;
      Store.setActVideoId(this.it.id);
      this.$router.push("/video");
    },
  },
};
</script>

<style lang="less" scoped>
.box-item {
  /* 矩形 6 */
  /* 矩形 6 */
  // width: 350px;
  height: 310px;
  border-radius: 10px;
  // background: #ffffff;
  // box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  margin-bottom: 33px;
  .video {
    /* 矩形 7 */
    height: 220px;
    // border-radius: 10px 10px 0px 0px;
    // border-radius: 5px;
    position: relative;
    overflow: hidden;
    img {
      transform: scaleY(1.35);
    }
    .videoThumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 220px;
    }
    .ft {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 37px;
      background: linear-gradient(
        180deg,
        rgba(44, 44, 44, 0.116) 0%,
        #000 100%
      );
      display: flex;
      padding: 0 9px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 12px;
      .l {
        display: flex;
        justify-content: space-between;
        img {
          height: 12px;
        }
        .watch {
          margin-left: 27px;
        }
      }
    }
    .jing {
      position: absolute;
      top: 0;
      right: -0;
      width: 200px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #ff7d41d5;
      /* 精品 */
      font-family: PingFang SC Medium;
      font-size: 12px;
      color: #ffffff;
      transform: rotate(45deg) translate(30%, -90%);
    }
  }
  .bot {
    .p {
      /* 稳定币发展历程稳定币发展历程稳定币发展历程稳定币发展历程 */
      // font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      height: 50px;
      line-height: 20px;
      letter-spacing: 0em;
      padding: 7px 9px 0px;
      margin-bottom: 9px;
      color: #333333;
      // overflow: hidden;
      // 超出省略号
      overflow: hidden;
      // font-weight: bold;

      text-overflow: ellipsis;

      display: -webkit-box;

      -webkit-box-orient: vertical;

      -webkit-line-clamp: 2;
    }
    .ft {
      padding: 0px 9px;
      justify-content: space-between;
      display: flex;
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
