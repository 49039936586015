<template>
  <div class="itemBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in store.topicsDoc"
        :key="index"
        :label="item.title"
        :name="item.id + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <el-row :gutter="13">
      <el-col :span="6" v-for="(item, index) in docList" :key="index">
        <box-3-item :item="item"></box-3-item>
      </el-col>
    </el-row>
    <div class="block"></div>
  </div>
</template>
<script>
import Store from '../../store';
import box3Item from './box3-item.vue';
export default {
  components: { box3Item },
  data() {
    return {
      store: Store.state,
      activeName: '',
      act: '',
      docList: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
    };
  },
  mounted() {
    this.getVideoStateList();
  },
  methods: {
    // 选择某一个选项
    handleClick(tab) {
      this.act = this.store.topicsDoc[tab.index].id;
      window.sessionStorage.setItem('wordAct', this.act);
      window.sessionStorage.setItem('wordActctiveName', this.activeName);
      // console.log(tab.index);
      // console.log(tab, event);
      this.pageSize = 8;
      this.pageNum = 1;
      // 如果是精品
      this.getVideoList();
    },
    // 获取文档分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${2}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.store.topicsDoc = res.data;
      // this.activeName = res.data[0].id;
      // this.act = res.data[0].id;

      this.activeName =
        window.sessionStorage.getItem('wordActctiveName') || res.data[0].id;
      this.act = window.sessionStorage.getItem('wordAct') || res.data[0].id;
      this.getVideoList();
    },
    // 获取视频列表
    async getVideoList() {
      let { data: res } = await this.$http.post('/learnDoc/topicDocs', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        topicId: this.act,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.docList = res.data.records;
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  min-height: 55vh;
  // .el-tab-pane {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }
}
</style>
